import { Component, ViewChild } from '@angular/core';

import { Platform, IonRouterOutlet, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';

import { FCM } from '@capacitor-community/fcm';
import { Plugins, PushNotificationActionPerformed, PushNotification, PushNotificationToken } from '@capacitor/core';
import { Router } from '@angular/router';
import { ThemeService } from './services/theme.service';
import { AnalyticsField } from './utils/constants';
import { FirebaseService } from './services/firebase.service';

const fcm = new FCM();
const { App, PushNotifications } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private router: Router,
    private themeService: ThemeService,
    private firebaseService: FirebaseService,
    private alertController: AlertController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.registerEvents();
      this.themeService.setAppTheme();
      this.setupTheme();
      this.setupPush();
      this.firebaseService.incrementAnalyticsField(AnalyticsField.APP_SESSIONS);
    });
  }

  registerEvents(): void {
    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (
        this.router.url === '/tabs/posts' ||
        this.router.url === '/tabs/dates' ||
        this.router.url === '/tabs/settings' ||
        this.router.url === '/'
      ) {
        App.exitApp();
      }
    });
  }

  // navigateToConference() {
  //   this.router.navigateByUrl(`/tabs/conference`);
  // }

  setupPush() {
    if (this.platform.is('capacitor')) {
      PushNotifications.addListener(
        'pushNotificationReceived',
        async (notification: PushNotification) => {
          const buttons: any[] = [];
          // if (notification?.data?.conference) {
          //   buttons.push({
          //     text: 'Zu den Details',
          //     handler: () => {
          //       this.navigateToConference();
          //     },
          //   });
          // }
          buttons.push({
            text: 'Okay',
          });
          const alert = await this.alertController.create({
            header: notification.title,
            message: notification.body,
            buttons: buttons,
          });

          await alert.present();
          this.firebaseService.incrementAnalyticsField(AnalyticsField.PUSH_OPENED_INSIDE, {
            indicator: notification.title
          });
        },
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          // if (notification.notification?.data?.conference) {
          //   this.navigateToConference();
          // }
          this.firebaseService.incrementAnalyticsField(AnalyticsField.PUSH_OPENED_OUTSIDE, {
            indicator: notification.notification.title
          });
        },
      );

      this.storage.get('oldUser').then((oldUser: boolean): void => {
        if (oldUser) {
          PushNotifications.requestPermission().then((res: any) => {
            if (res.granted) {
              PushNotifications.register()
                .then(() => {
                  fcm
                    .subscribeTo({ topic: 'general' })
                    .then(() => console.log('subscribed successfully'))
                    .catch(err => console.log(err));
                })
                .catch((err) => console.log(err));
            }
          });
        }
      });
    }
  }

  setupTheme() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.toggleDarkTheme(prefersDark.matches);
    prefersDark.addListener((mediaQuery: any) => this.toggleDarkTheme(mediaQuery.matches));
  }

  toggleDarkTheme(shouldAdd: boolean): void {
    document.body.classList.toggle('dark', shouldAdd);
  }
}

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { CalendarDate, FirebasePost, CategoryData, Category } from '../utils/interfaces';
import { Observable, Subscriber } from 'rxjs';
import { take, first, finalize } from 'rxjs/operators';
import { Utils } from '../utils/utils';
import { firestore } from 'firebase/app';
import * as firebase from 'firebase/app';
import { AnalyticsField } from '../utils/constants';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FCM } from '@capacitor-community/fcm';
import { Platform } from '@ionic/angular';
import { Howl } from 'howler';
import { FileLikeObject } from 'ng2-file-upload';

const fcm = new FCM();

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  subscriber: Subscriber<boolean>;
  datesCollection: AngularFirestoreCollection<CalendarDate>;
  posts: FirebasePost[];
  rubrics: Category[];
  ausgaben: Category[];
  legalPages: any[];

  constructor(
    private db: AngularFirestore,
    private utils: Utils,
    private afAuth: AngularFireAuth,
    private platform: Platform,
    private fns: AngularFireFunctions,
    private fireStorage: AngularFireStorage,
  ) {
    this.init();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.datesCollection = this.db.collection<CalendarDate>(
      'dates',
      (ref: any) => ref.where('end', '>=', yesterday).orderBy('end'),
    );
  }

  async init() {
    await this.loadCategories();
  }

  async sendTestPush(notification, data) {
    const callable: (res: any) => Observable<any> = this.fns.httpsCallable(
      'sendTestPush',
    );
    await callable({
      data,
      notification,
    }).toPromise();
  }

  async sendPush(notification, data) {
    const callable: (res: any) => Observable<any> = this.fns.httpsCallable(
      'sendPush',
    );
    await callable({
      data,
      notification,
    }).toPromise();
  }

  incrementAnalyticsField(field: AnalyticsField, data: any = {}) {
    const now: Date = new Date();
    const increment = firestore.FieldValue.increment(1);
    const analyticsData: any = firestore.FieldValue.arrayUnion({
      ...data,
      timestamp: firebase.firestore.Timestamp.fromDate(now),
      platform: this.utils.getPlatform(),
    });

    this.db.doc('analytics/overall').set(
      {
        [`${field}Count`]: increment,
        [`${field}Data`]: analyticsData,
      },
      { merge: true },
    );
  }

  getAnalyticsOverview() {
    return this.db.doc('analytics/overall').valueChanges();
  }

  incrementPostViews(id: string) {
    const increment = firestore.FieldValue.increment(1);
    this.db.doc(`posts/${id}`).update({
      views: increment,
    });
  }

  getDates(): AngularFirestoreCollection<CalendarDate> {
    return this.datesCollection;
  }

  getConferenceData() {
    return this.db.doc<any>('konferenz/2021').valueChanges().pipe(take(1)).toPromise();
  }

  async getPost(id: string) {
    if (this.posts) {
      return this.posts.find((post: FirebasePost) => post.id === id);
    } else {
      await this.getPosts().pipe(take(1)).toPromise();
      return this.posts.find((post: FirebasePost) => post.id === id);
    }
  }

  getPosts() {
    return new Observable(observer => {
      this.db
        .collection('posts')
        .valueChanges()
        .subscribe((posts: FirebasePost[]) => {
          this.posts = this.getEditedPosts(posts)
            .sort((a: FirebasePost, b: FirebasePost) => {
              if (a.ausgabe && b.ausgabe) {
                if (a.ausgabe.id > b.ausgabe.id) {
                  return 1;
                } else if (a.ausgabe.id < b.ausgabe.id) {
                  return -1;
                }
              } else if (a.ausgabe && !b.ausgabe) {
                return 1;
              } else if (!a.ausgabe && b.ausgabe) {
                return -1;
              }

              if (a.rubrik && b.rubrik) {
                if (a.rubrik.id < b.rubrik.id) {
                  return 1;
                } else if (a.rubrik.id > b.rubrik.id) {
                  return -1;
                }
              } else if (a.rubrik && !b.rubrik) {
                return 1;
              } else if (!a.rubrik && b.rubrik) {
                return -1;
              }

              return 0;
            })
            .reverse();
          observer.next(this.posts);
        });
    });
  }

  getEditedPosts(posts: FirebasePost[]): FirebasePost[] {
    return posts.map((post: any) => {
      const categroyData: CategoryData = this.utils.getCategoryData(
        post,
        this.rubrics,
        this.ausgaben,
      );
      return {
        ...post,
        rubrik: categroyData.rubrik,
        ausgabe: categroyData.ausgabe
      };
    });
  }

  async loadCategories() {
    if (this.ausgaben && this.rubrics) {
      return Promise.resolve();
    } else {
      const categories: any = await this.db
        .collection<Category[]>('categories')
        .valueChanges()
        .pipe(take(1))
        .toPromise();
      const ausgabenCategory = categories.find(
        (cat: any) => cat.name === 'Komplette Ausgabe',
      );
      const rubrikenCategory = categories.find(
        (cat: any) => cat.name === 'Kategorien',
      );
      this.ausgaben = categories
        .filter(
          (cat: Category) =>
            cat.parent.toString() === ausgabenCategory.id && cat.count !== 0,
        )
        .sort((a: Category, b: Category) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        })
        .reverse();
      this.rubrics = categories
        .filter(
          (cat: Category) =>
            cat.parent.toString() === rubrikenCategory.id && cat.count !== 0,
        )
        .sort((a: Category, b: Category) => a.id - b.id);
    }
  }

  getAusgaben(): Category[] {
    return this.ausgaben;
  }

  getRubrics() {
    return this.rubrics;
  }

  async loadLegalPages() {
    if (this.legalPages) {
      return;
    }
    this.legalPages = await this.db
      .collection<any[]>('pages')
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getImprint() {
    await this.loadLegalPages();
    return this.legalPages.find((page: any) => page.id === 'imprint');
  }

  async getDataProt() {
    await this.loadLegalPages();
    return this.legalPages.find((page: any) => page.id === 'dataprot');
  }

  login(email: string, password: string) {
    return new Promise((resolve: any) => {
      this.afAuth
        .auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          if (this.platform.is('capacitor')) {
            fcm.subscribeTo({ topic: 'admin' });
          }
          if (this.subscriber) {
            this.subscriber.next(true);
          }
          this.incrementAnalyticsField(AnalyticsField.ADMIN_LOGGED_IN);
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async isAdmin(): Promise<boolean> {
    const user: any = await this.afAuth.authState.pipe(first()).toPromise();
    if (this.subscriber) {
      this.subscriber.next(Boolean(user));
    }
    return Boolean(user);
  }

  subscribeToAdmin(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      this.subscriber = subscriber;
      this.isAdmin();
    });
  }

  async updateAudioDurations(): Promise<void> {
    await this.getPosts().pipe(take(1)).toPromise();

    this.posts.forEach((post: FirebasePost) => {
      if (post.audio && !post.audioDuration) {
        const sound = new Howl({
          html5: true,
          src: [post.audio],
          preload: true,
        });
        sound.on('load', () => {
          this.db.doc(`posts/${post.id}`).update({
            audioDuration: this.utils.getMinString(
              Math.round(sound.duration()),
            ),
          });
        });
      }
    });
  }

  uploadImageFile(file: FileLikeObject) {
    return new Promise((resolve: any) => {
      const path = `/other/${Date.now()}_${file.name}`;
      const ref = this.fireStorage.ref(path);
      const task = this.fireStorage.upload(path, file.rawFile);

      task
        .snapshotChanges()
        .pipe(
          finalize(async () => {
            const url = await ref.getDownloadURL().toPromise();
            resolve({
              url,
              path,
            });
          }),
        )
        .subscribe();
    });
  }
}

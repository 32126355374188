export enum Theme {
  COMPLEX = 'complex',
  SIMPLE = 'simple',
}

export enum AnalyticsField {
  APP_INSTALLATIONS = 'appInstallations',
  RUBRIK_CHANGED = 'rubrikChanged',
  AUSGABE_CHANGED = 'ausgabeChanged',
  APP_SESSIONS = 'appSessions',
  ADMIN_LOGGED_IN = 'adminLoggedIn',
  POST_TIME = 'postTime',
  AUDIO_PLAYED = 'audioPlayed',
  PUSH_OPENED_OUTSIDE = 'pushOpenedOutside',
  PUSH_OPENED_INSIDE = 'pushOpenedInside'
}

export enum Platforms {
  IPAD = 'iPad',
  IPHONE = 'iPhone',
  ANDROID_PHONE = 'AndroidPhone',
  ANDROID_TABLET = 'AndroidTablet',
  WEB = 'Web',
  OTHER = 'Other',
}
